import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { upperFirst } from 'lodash-es';

import { ConfigService } from '@cosmos/config';
import type { SearchCriteria, SearchResult } from '@esp/common/types';
import type {
  Order,
  OrderMessage,
  OrderSearch,
  ProductLineItem,
} from '@esp/orders/types';

@Injectable({ providedIn: 'root' })
export class CustomerPortalQuotesApiService {
  private readonly _apiUrl: string;

  constructor(
    private readonly _http: HttpClient,
    configService: ConfigService
  ) {
    this._apiUrl = configService.get('marsApiUrl');
  }

  /** Loads full details for a quote by ID underneath a project. */
  getQuote(projectId: string | number, quoteId: string | number) {
    return this._http.get<Order<ProductLineItem>>(
      `${this._apiUrl}/projects/${projectId}/orders/${quoteId}`
    );
  }

  /** Get quotes for a project that are set to customer visible. */
  getCustomerVisibleQuotes(
    projectId: string | number,
    criteria: SearchCriteria
  ) {
    const body = (Object.keys(criteria) as Array<keyof SearchCriteria>).reduce(
      (body, key) => {
        if (criteria[key] !== undefined) {
          // `upperFirst` is used to convert the first character to an uppercase.
          // For instance, the `size -> Size`.
          body[upperFirst(key)] = criteria[key];
        }

        return body;
      },
      <Record<string, unknown>>{}
    );

    return this._http.post<SearchResult<OrderSearch>>(
      `${this._apiUrl}/projects/${projectId}/orders/type/quote`,
      body
    );
  }

  submitChangeRequest(
    projectId: number,
    quoteId: number,
    messages: OrderMessage[]
  ) {
    return this._http.post<void>(
      `${this._apiUrl}/projects/${projectId}/quote/${quoteId}/messages`,
      messages
    );
  }

  approveQuote(projectId: number, quoteId: number) {
    return this._http.put<void>(
      `${this._apiUrl}/projects/${projectId}/quotes/${quoteId}/approve`,
      {}
    );
  }
}
